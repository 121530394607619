import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.main');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-main',
template: templateSource || '',
    styles: [`

/* file: /opt/cellytics/branch/main/build/src/app/page.main/view.scss */
.view-container {
  width: 100%;
  max-width: 1024px;
  padding: 98px 0;
  margin: 0 auto;
}
@media (max-width: 768px) {
  .view-container {
    padding: 24px;
  }
}

.bg-white {
  background-color: #fff !important;
}

.bg-light {
  background-color: #F1F1F3 !important;
}

.bg-dark-gray {
  background-color: #A4AAB4 !important;
}

.card {
  background: #FFFFFF;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1019607843);
  border-radius: 30px;
  border: none;
}
.card .card-body {
  border: none;
  padding: 48px 36px;
}

.mit-badge {
  display: inline-block;
  background: #DAE6FA;
  border-radius: 16px;
  color: #256FF1;
  padding: 4px 18px;
  font-weight: 500;
}

.content-title {
  text-align: center;
}
.content-title h2 {
  line-height: 1;
  font-size: 50px;
  margin-bottom: 12px;
  margin-top: 18px;
}
.content-title h3 {
  line-height: 1;
  font-size: 30px;
  margin-bottom: 24px;
  font-family: "main-r";
  font-weight: 500;
}

.content-body {
  border-top: 1px solid #D0D0D0;
  text-align: center;
}
@media (max-width: 768px) {
  .content-body {
    border-top: none;
  }
}
.content-body .label {
  display: inline-block;
  padding: 4px 24px;
  border: 2px solid #2249ED;
  border-radius: 24px;
  color: #2249ED;
  font-size: 24px;
  font-weight: 1000;
  margin-top: 32px;
}
.content-body .row .col-md-6 {
  border-left: 1px solid #D0D0D0;
}
@media (max-width: 768px) {
  .content-body .row .col-md-6 {
    border-left: none;
  }
}
.content-body .row .col-md-6:first-child {
  border-left: none;
}

.card-contact {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(/assets/featured/contact.jpg);
}
.card-contact .content-title {
  text-align: left;
  color: #fff;
}
.card-contact .content-title h2 {
  font-size: 42px;
}
@media (max-width: 768px) {
  .card-contact .content-title h2 {
    font-size: 24px;
  }
}
.card-contact .btn-blue {
  padding: 12px 24px;
  border-radius: 24px;
}

.product-area {
  text-align: center;
}
.product-area .col-md-6 {
  padding: 0 18px;
}
.product-area h2 {
  font-size: 42px;
  margin-bottom: 52px;
}
.product-area .product-card {
  background: #F1F1F3;
  border: 3px solid #F1F1F3;
  border-radius: 30px;
  padding: 64px;
  margin-top: 24px;
  flex: 1 1 auto;
  cursor: pointer;
}
.product-area .product-card:hover {
  border: 3px solid #256FF1;
}
.product-area .product-card img {
  height: 220px;
}
.product-area .product-card .text-container {
  width: 100%;
  max-width: 320px;
  margin: 0 auto;
}
.product-area .product-card h3 {
  font-size: 32px;
  margin-top: 52px;
  margin-bottom: 16px;
  line-height: 32px;
}
.product-area .product-card span {
  color: #707070;
}`],
})
export class PageMainComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
        await this.service.render();
    }
}

export default PageMainComponent;