import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.footer');
import { OnInit } from '@angular/core';

@Component({
    selector: 'wiz-component-footer',
template: templateSource || '',
    styles: [`

/* file: /opt/cellytics/branch/main/build/src/app/component.footer/view.scss */
.content {
  background: #fff;
  color: #5D5F6F;
  overflow: hidden;
}
.content .content-container {
  padding: 64px 24px;
  margin: 0 auto;
  max-width: 1320px;
}
@media (max-width: 768px) {
  .content .d-flex {
    display: block !important;
  }
}

h1 {
  font-size: 40px;
  color: #656468;
}

.footer-heading {
  margin-bottom: 24px;
}`],
})
export class ComponentFooterComponent implements OnInit {
    public async ngOnInit() {
    }
}

export default ComponentFooterComponent;