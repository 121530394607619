import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.product.slide');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-product-slide',
template: templateSource || '',
    styles: [`

/* file: /opt/cellytics/branch/main/build/src/app/page.product.slide/view.scss */
.view-container {
  width: 100%;
  max-width: 1024px;
  padding: 98px 0;
  margin: 0 auto;
}

.product-images {
  display: flex;
}
.product-images .product-featured-images {
  background: #F1F1F3;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.product-images .product-featured-images img {
  width: 70%;
}
.product-images .product-minimap-images .product-minimap-image {
  background: #F1F1F3;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 120px;
  margin-bottom: 12px;
  margin-left: 16px;
  cursor: pointer;
}
.product-images .product-minimap-images .product-minimap-image:last-child {
  margin-bottom: 0;
}
.product-images .product-minimap-images .product-minimap-image img {
  width: 80%;
  opacity: 0.3;
}
.product-images .product-minimap-images .product-minimap-image.active, .product-images .product-minimap-images .product-minimap-image:hover {
  border: 1px solid #256FF1;
}
.product-images .product-minimap-images .product-minimap-image.active img, .product-images .product-minimap-images .product-minimap-image:hover img {
  opacity: 1;
}

.product-intro {
  padding-left: 32px;
}
@media (max-width: 768px) {
  .product-intro {
    margin-top: 48px;
    padding-left: 24px;
    padding-right: 24px;
  }
}
.product-intro .product-subtitle {
  color: #707070;
  font-size: 18px;
}
.product-intro .product-title {
  font-size: 46px;
  font-family: "main-eb";
  font-weight: 1000;
}
.product-intro .product-desc {
  color: #707070;
  margin-top: 24px;
  margin-bottom: 24px;
}
.product-intro .product-detail .product-detail-title {
  width: 180px;
  display: inline-block;
  font-weight: 1000;
}
.product-intro .product-detail .product-detail-text {
  display: inline-block;
}
.product-intro .product-btns {
  margin-top: 48px;
  text-align: center;
}
.product-intro .product-btns .btn {
  padding: 12px 24px;
  border-radius: 24px;
}

.hr {
  margin-top: 64px;
  margin-bottom: 64px;
}

.product-content {
  text-align: center;
}
.product-content img {
  width: 100%;
  max-width: 720px;
}
.product-content .content-heading {
  margin-top: 48px;
}
.product-content .content-heading .content-number {
  color: #2249ED;
  font-size: 24px;
  font-family: "main-eb";
  font-weight: 1000;
}
.product-content .content-heading .content-title {
  font-size: 50px;
  margin-top: 4px;
  font-family: "main-eb";
  font-weight: 1000;
}
.product-content .content-desc {
  width: 660px;
  margin: 0 auto;
  color: #707070;
  font-size: 20px;
  margin-top: 32px;
  margin-bottom: 48px;
}
@media (max-width: 768px) {
  .product-content .content-desc {
    width: 100%;
    padding: 24px;
  }
}
.product-content table {
  margin-top: 92px;
  width: 100%;
  text-align: left;
}
.product-content table th {
  background-color: #F1F1F3;
}
.product-content table th,
.product-content table td {
  padding: 12px 16px;
  border-top: 1px solid #707070;
  border-bottom: 1px solid #707070;
}`],
})
export class PageProductSlideComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
        await this.service.render();
    }
}

export default PageProductSlideComponent;