import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.contact');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-contact',
template: templateSource || '',
    styles: [`

/* file: /opt/cellytics/branch/main/build/src/app/page.contact/view.scss */
.header {
  background-image: linear-gradient(108deg, rgba(0, 0, 0, 0.5), transparent), url("/assets/featured/contact.jpg");
}

.content .d-flex {
  padding: 0;
}
.content .form-check {
  display: flex;
  padding: 0;
  font: normal normal normal 16px/28px SUIT;
}
@media (max-width: 768px) {
  .content .form-check {
    display: block;
    white-space: nowrap;
  }
  .content .form-check .mr-3 {
    margin-right: 0 !important;
    margin-bottom: 6px;
  }
  .content .form-check .box {
    display: flex;
    align-items: center;
  }
}
.content .form-check .box .form-check-input {
  --tblr-form-check-bg-image: none;
  background-color: white;
  width: 18px;
  height: 18px;
  opacity: 1;
  margin: 4px 12px 0px 0px;
}
.content .form-check .box .form-check-input:checked {
  background-color: #494A52;
  border: 3px solid white;
}
.content .form-check .box .form-check-input:focus {
  box-shadow: none;
}
.content .form-check .box .form-check-label {
  cursor: pointer;
}
.content .form-check .box:hover, .content .form-check .box:has(.form-check-input:checked) {
  background-color: #2249ED;
  color: white;
  transition-property: background-color;
  transition-duration: 0.5s;
  cursor: pointer;
}
.content .row {
  width: 1180px;
}
.content .number {
  color: #2249ED;
  font: normal normal 600 24px Montserrat;
  margin-bottom: 10px;
}
.content .title {
  font: normal normal bold 28px/28px SUIT;
  margin-bottom: 20px;
}
.content .sub {
  font: normal normal 600 18px/28px SUIT;
}
.content .box {
  color: black;
  border: none;
  background: #F4F4F4 0% 0% no-repeat padding-box;
  border-radius: 10px;
  height: 60px;
}
.content input.form-control {
  height: 60px;
}
.content .form-control {
  padding-left: 20px;
  border-radius: 10px;
  background: #F4F4F4 0% 0% no-repeat padding-box;
  border: none;
  color: #72787F;
  margin: 10px 0px;
}
.content .form-control:focus {
  border: 1px solid #2249ED;
  box-shadow: none !important;
  transition-property: border-color;
  transition-duration: 0.3s;
  transition-timing-function: ease;
}
.content .btn-file {
  border-radius: 10px;
  background: white;
  color: #2249ED;
  border: 2px solid #2249ED;
  width: 170px;
  height: 60px;
}
.content .btn-file:hover {
  background-color: #2249ED;
  color: white;
}
.content .btn-season {
  border-radius: 10px;
  background-color: #2249ED;
  color: white;
  width: 170px;
  height: 60px;
}

@media (max-width: 768px) {
  .d-flex {
    display: block !important;
  }
  .d-flex .mr-3 {
    margin-right: 0 !important;
    margin-bottom: 6px;
  }
}`],
})
export class PageContactComponent implements OnInit {
    private list: any = {
        inquiry: '제품문의'
    };

    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
    }

    public async alert(message: string, status: string = 'error', cancel: any = false, action: string = '확인') {
        return await this.service.alert.show({
            title: "",
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }

    public async submit() {
        let message = "";
        let status = "error";

        let msg: any = await this.service.lang.translate('contact.message');

        if (!this.list.inquiry) {
            message = msg.inquiry;
        } else if (!this.list.company) {
            message = msg.company;
        } else if (!this.list.manager) {
            message = msg.manager;
        } else if (!this.list.number) {
            message = msg.number;
        } else if (!this.list.email) {
            message = msg.email;
        } else if (!this.list.content) {
            message = msg.content;
        } else {
            message = msg.success;
            status = "success";

            const { code } = await wiz.call("submit", this.list);
            if (code !== 200) {
                let error = msg.error;
                await this.alert(error);
                return;
            }
        }

        await this.alert(message, status);

        if (status == "success") {
            this.service.href("/main");
        }
    }
}

export default PageContactComponent;