import '@angular/compiler';
import { ComponentFooterComponent } from './component.footer/component.footer.component';
import { PortalSeasonLoadingDefaultComponent } from './portal.season.loading.default/portal.season.loading.default.component';
import { PageProductAsckitComponent } from './page.product.asckit/page.product.asckit.component';
import { PortalSeasonPagenationComponent } from './portal.season.pagenation/portal.season.pagenation.component';
import { PageProductCellyticsComponent } from './page.product.cellytics/page.product.cellytics.component';
import { PageContactComponent } from './page.contact/page.contact.component';
import { PortalSeasonLoadingSeasonComponent } from './portal.season.loading.season/portal.season.loading.season.component';
import { LayoutMainComponent } from './layout.main/layout.main.component';
import { ComponentNavigationComponent } from './component.navigation/component.navigation.component';
import { AlertComponent } from './alert/alert.component';
import { PortalWebSolutionDizestComponent } from './portal.web.solution.dizest/portal.web.solution.dizest.component';
import { PortalSeasonUiDropdownComponent } from './portal.season.ui.dropdown/portal.season.ui.dropdown.component';
import { PortalSeasonViewerTreeComponent } from './portal.season.viewer.tree/portal.season.viewer.tree.component';
import { PageProductSepkitComponent } from './page.product.sepkit/page.product.sepkit.component';
import { PortalWebSolutionWorksComponent } from './portal.web.solution.works/portal.web.solution.works.component';
import { PortalSeasonLoadingHexaComponent } from './portal.season.loading.hexa/portal.season.loading.hexa.component';
import { PageAuthenticateComponent } from './page.authenticate/page.authenticate.component';
import { PortalWebSolutionWizComponent } from './portal.web.solution.wiz/portal.web.solution.wiz.component';
import { LayoutProductComponent } from './layout.product/layout.product.component';
import { LayoutAdminComponent } from './layout.admin/layout.admin.component';
import { PageMainComponent } from './page.main/page.main.component';
import { PageSolutionsComponent } from './page.solutions/page.solutions.component';
import { PortalSeasonAlertComponent } from './portal.season.alert/portal.season.alert.component';
import { PortalSeasonTabComponent } from './portal.season.tab/portal.season.tab.component';
import { PageProductSlideComponent } from './page.product.slide/page.product.slide.component';
import { LayoutEmptyComponent } from './layout.empty/layout.empty.component';
import { PortalSeasonStatusbarComponent } from './portal.season.statusbar/portal.season.statusbar.component';
import { LayoutContactComponent } from './layout.contact/layout.contact.component';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, COMPOSITION_BUFFER_MODE } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { SortablejsModule } from "src/libs/portal/season/ngx-sortablejs";

@NgModule({
    declarations: [
        AppComponent,
        ComponentFooterComponent,
        PortalSeasonLoadingDefaultComponent,
        PageProductAsckitComponent,
        PortalSeasonPagenationComponent,
        PageProductCellyticsComponent,
        PageContactComponent,
        PortalSeasonLoadingSeasonComponent,
        LayoutMainComponent,
        ComponentNavigationComponent,
        AlertComponent,
        PortalWebSolutionDizestComponent,
        PortalSeasonUiDropdownComponent,
        PortalSeasonViewerTreeComponent,
        PageProductSepkitComponent,
        PortalWebSolutionWorksComponent,
        PortalSeasonLoadingHexaComponent,
        PageAuthenticateComponent,
        PortalWebSolutionWizComponent,
        LayoutProductComponent,
        LayoutAdminComponent,
        PageMainComponent,
        PageSolutionsComponent,
        PortalSeasonAlertComponent,
        PortalSeasonTabComponent,
        PageProductSlideComponent,
        LayoutEmptyComponent,
        PortalSeasonStatusbarComponent,
        LayoutContactComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        NgbModule,
        BrowserAnimationsModule,
        BrowserModule,
        HttpClientModule,
        SortablejsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            },
            defaultLanguage: 'ko'
        })
        
    ],
    providers: [{
        provide: COMPOSITION_BUFFER_MODE,
        useValue: false
    }],
    bootstrap: [AppComponent]
})
export class AppModule { }

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/lang/', '.json');
}