import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('layout.main');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-layout-main',
template: templateSource || '',
    styles: [`

/* file: /opt/cellytics/branch/main/build/src/app/layout.main/view.scss */
body {
  width: 100%;
}

.wiz-page {
  width: 100%;
  margin: 0;
  z-index: 2;
  flex-direction: column;
}
@media (min-width: 769px) {
  .wiz-page {
    height: 100%;
    overflow: auto;
    display: block;
  }
}

.wiz-nav {
  max-width: 1448px;
  width: 90vw;
  margin: 0px auto;
}
@media (min-width: 769px) {
  .wiz-nav {
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 99999;
  }
}
@media (max-width: 768px) {
  .wiz-nav {
    width: 92vw;
  }
}

.wiz-view {
  width: 100%;
  z-index: 1;
}
@media (max-width: 768px) {
  .wiz-view {
    height: 100%;
    overflow: auto;
  }
  .wiz-view::-webkit-scrollbar {
    display: none;
  }
}
.wiz-view .view-header {
  position: relative;
  z-index: 1001;
  margin-top: -98px;
  height: 480px;
  max-height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(/assets/featured/main.jpg);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: end;
  padding-bottom: 64px;
}
@media (max-width: 768px) {
  .wiz-view .view-header {
    margin-top: 0;
    height: 240px;
    min-height: 0;
    text-align: center;
  }
}
.wiz-view .view-header .view-header-context {
  width: 100%;
  max-width: 1280px;
  padding: 32px;
  margin: 0 auto;
}
.wiz-view .view-header .subtitle {
  font-size: 54px;
}
@media (max-width: 768px) {
  .wiz-view .view-header .subtitle {
    font-size: 24px;
  }
}
.wiz-view .view-header .main-title {
  font-size: 84px;
  font-weight: 1000;
  font-family: "main-eb";
}
@media (max-width: 768px) {
  .wiz-view .view-header .main-title {
    font-size: 42px;
  }
}
.wiz-view .wiz-view-container {
  min-height: 100vh;
}
.wiz-view .view-margin-sticky {
  position: sticky;
  top: 0;
  width: 100%;
  height: 98px;
  background-color: #000;
  margin-top: -98px;
  z-index: 1000;
}
@media (max-width: 768px) {
  .wiz-view .view-margin-sticky {
    display: none;
  }
}`],
})
export class LayoutMainComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
    }
}

export default LayoutMainComponent;