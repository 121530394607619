import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.navigation');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { Component } from '@angular/core';
import { HostListener } from '@angular/core';

@Component({
    selector: 'wiz-component-navigation',
template: templateSource || '',
    styles: [`

/* file: /opt/cellytics/branch/main/build/src/app/component.navigation/view.scss */
.season-nav {
  height: 50px;
  display: flex;
  flex-direction: row;
  z-index: 11;
}
@media (max-width: 768px) {
  .season-nav {
    margin-top: 8px;
    margin-bottom: 12px;
  }
}
@media (min-width: 769px) {
  .season-nav {
    color: #fff;
    height: 98px;
    padding-top: 24px;
    padding-bottom: 24px;
  }
}
.season-nav .logo {
  display: flex;
  align-items: center;
  height: 50px;
  border-radius: 15px;
  padding: 20px 50px;
  cursor: pointer;
  font-size: 40px;
  font-family: "main-eb";
}
.season-nav .menu {
  display: flex;
  align-items: center;
  height: 50px;
  width: 124px;
  padding: 0 12px;
  cursor: pointer;
  border-radius: 15px;
  justify-content: center;
  margin: 0px 10px;
  position: relative;
}
.season-nav .menu span {
  margin-top: 10px;
}
.season-nav .menu h3 {
  font-family: "main-r";
  font-size: 18px;
  font-weight: 500;
}
.season-nav .menu:hover h3 {
  font-family: "main-eb";
  font-weight: 1000;
}
.season-nav .menu.highlight h3 {
  font-family: "main-eb";
  font-weight: 1000;
}
.season-nav .menubar {
  width: 50px;
  margin-right: 0 !important;
}
.season-nav .box {
  display: flex;
  position: relative;
  margin: 0px 10px;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 130px;
  border-radius: 15px;
  background-color: white;
  cursor: pointer;
}
.season-nav .box span {
  margin-top: 10px;
}
.season-nav .box h3 {
  font: normal normal bold 18px/22px Montserrat;
  margin: 0;
  padding: 14px 17px;
}
.season-nav .box button {
  padding: 0px;
  width: 170px;
  border: none;
  border-radius: 15px;
}
.season-nav .box button:active {
  color: white;
}
.season-nav .on {
  background-color: #F75933;
  color: white;
  cursor: pointer;
  border-radius: 15px;
}

.nav-toggler {
  display: none;
}

.nav-collapse {
  display: block;
}

@media (max-width: 768px) {
  .nav-toggler {
    display: block;
  }
  .nav-collapse {
    display: none;
  }
}
.nav-menus {
  display: none;
  color: #fff;
}
@media (max-width: 768px) {
  .nav-menus {
    display: block;
    width: 100%;
    height: 100%;
    background-color: #000;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    overflow: auto;
  }
  .nav-menus.closed {
    display: none;
  }
}
.nav-menus .close-btn {
  width: 100%;
  padding-top: 24px;
  padding-right: 36px;
  text-align: right;
  font-size: 36px;
}
.nav-menus .menu-item-container {
  padding: 24px 48px;
}
.nav-menus .menu-item-container .menu-item {
  color: #fff;
  padding: 24px 12px;
  padding-top: 24px;
  padding-bottom: 20px;
  border-bottom: 1px solid #fff;
  font-size: 18px;
}
.nav-menus .lang {
  padding: 24px 48px;
  font-size: 18px;
}
.nav-menus .lang .col {
  text-align: center;
}`],
})
export class ComponentNavigationComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
    }

    @HostListener('document:click')
    public clickout() {
        this.service.navbar.toggle(true);
    }

    public async switchLanguage(lang: string) {
        await this.service.lang.set(lang);
    }

    public isActive(link: string) {
        return location.pathname.indexOf(link) === 0
    }
}

export default ComponentNavigationComponent;