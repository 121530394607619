import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.web.solution.dizest');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-web-solution-dizest',
template: templateSource || '',
    styles: [`

/* file: /opt/cellytics/branch/main/build/src/app/portal.web.solution.dizest/view.scss */
.solution-intro {
  background-color: #2b2c2e !important;
  padding-top: 64px;
}
.solution-intro .info {
  display: flex;
  color: white;
  height: 140px;
  margin: auto;
  align-items: center;
}
@media (max-width: 768px) {
  .solution-intro .info {
    display: block;
    height: auto;
  }
}
.solution-intro .info .group {
  cursor: pointer;
  margin-right: 64px;
  margin-bottom: 24px;
}
.solution-intro .info .group .col-auto {
  display: flex;
  align-items: end;
}
.solution-intro .info .group h2 {
  font-family: Montserrat;
  font-weight: bold;
  font-size: 64px;
  margin-bottom: 0px;
  padding: 0;
  cursor: pointer;
}
@media (max-width: 768px) {
  .solution-intro .info .group h2 {
    font-size: 48px;
  }
}
.solution-intro .info .group img {
  width: 40px;
  height: 40px;
}
.solution-intro .info h3 {
  font-family: SUIT;
  font-weight: bold;
  font-size: 20px;
  margin-top: 14px;
  margin-bottom: 14px;
}
.solution-intro .info span {
  color: #e9e8ee;
  font-family: SUIT;
  font-weight: normal;
  font-size: 16px;
}
.solution-intro .box {
  margin: auto;
}
.solution-intro .box img {
  position: relative;
  width: 100%;
  margin-top: 15px;
  border-radius: 20px;
}

.innovation {
  position: relative;
  display: flex;
  height: 100%;
  margin: auto;
  max-width: 1180px;
}
.innovation .col-md-3 {
  display: flex;
  padding: 16px;
}
.innovation .innovation-card {
  padding: 30px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  color: white;
}
.innovation .innovation-card h3 {
  text-align: center;
  font-weight: bold;
  font-family: SUIT;
  font-size: 20px;
}
.innovation .innovation-card .index {
  margin-bottom: 10px;
}
.innovation .innovation-card img {
  padding: 40px;
}
.innovation .innovation-card span {
  text-align: center;
  font-weight: normal;
  font-family: SUIT;
  font-size: 16px;
  line-height: 20px;
  white-space: break-spaces;
}
.innovation .innovation-card .text-gray {
  color: #9799A6;
}
.innovation .innovation-card .text-black {
  color: black;
}
.innovation .one {
  background-color: white;
}
.innovation .two {
  background-color: #9799A6;
}
.innovation .three {
  background-color: #494A52;
}
.innovation .four {
  background-color: #2E2F34;
}

.cases {
  display: flex;
  margin: auto;
  height: 90%;
  border-top: 1px solid #707070;
}
@media (max-width: 768px) {
  .cases {
    display: block;
  }
}
.cases .col-auto {
  display: flex;
  flex-direction: column;
  width: 240px;
  padding-top: 16px;
}
@media (max-width: 768px) {
  .cases .col-auto {
    width: 100%;
    display: block;
    white-space: nowrap;
    overflow: auto;
  }
  .cases .col-auto::-webkit-scrollbar {
    display: none;
  }
}
.cases .col-auto .cursor-pointer {
  display: block;
  margin-bottom: 12px;
  padding: 6px 0px;
  font-family: SUIT;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #494A52;
}
@media (max-width: 768px) {
  .cases .col-auto .cursor-pointer {
    display: inline-block;
    margin: 0 16px;
  }
}
.cases .col-auto .focus {
  color: #F75933;
  font-weight: bold;
}
.cases .col {
  display: flex;
  border-left: 1px solid #707070;
  padding: 45px;
  padding-right: 0;
  flex-direction: column;
}
@media (max-width: 768px) {
  .cases .col {
    padding: 0;
    border-left: none;
    margin-top: 24px;
  }
}
.cases .col .capture {
  display: flex;
  align-items: center;
}
.cases .col .capture img {
  width: 100%;
  border-radius: 0;
}
.cases .col .detail {
  margin: auto;
  margin-bottom: 32px;
}
.cases .col .detail h3 {
  font-family: SUIT;
  font-weight: bold;
  font-size: 24px;
  line-height: 1;
  color: #F75933;
  margin-bottom: 2px;
  padding: 0;
}
.cases .col .detail h2 {
  font-family: SUIT;
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
  margin-bottom: 16px;
  padding: 0;
  padding-left: 6px;
}
.cases .col .detail span {
  font: normal normal normal 16px/25px SUIT;
}`],
})
export class PortalWebSolutionDizestComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
        await this.select(this.data[0]);
    }

    public selected: any = null;

    public data: any = [
        {
            index: "01",
            src: "/assets/page/solutions/dizest/case-1.png",
            ko: {
                title: "초분광 항공 영상 분석",
                detail: "초분광 항공 영상에서 해상 객체를 탐지하는 기술은 해상 사고 발생 시 인명 구조, 바다 위의 부유물 탐지 등 해양 안전과 밀접한 기술입니다.  DIZEST는 이 기술을 개발하기 위한 연구 도구로 활용되어 SCI 논문 등 다양한 연구 성과를 도출하는데 사용되었고, 대용량 데이터 처리가 가능함을 실증하였습니다.",
            },
            en: {
                title: "Hyperspectral Image analysis",
                detail: "The technology for detecting maritime objects in hyperspectral aerial images is a technology closely related to maritime safety, such as saving lives in the event of a maritime accident or detecting floating objects on the sea. DIZEST was used as a research tool to develop this technology and was used to derive various research results, including SCI papers, and demonstrated that large-scale data processing is possible.",
            }
        }, {
            index: "02",
            src: "/assets/page/solutions/dizest/case-2.png",
            ko: {
                title: "생명공학",
                detail: "DIZEST는 렌즈프리 기술을 활용한 세포 분석 연구에도 활용되었습니다. 코드 개발에 익숙하지 않은 생명공학 연구자들도 머신러닝 기반의 연구를 수월하게 진행할 수 있습니다. 실제로 생명공학 연구팀에서 DIZEST를 도입하여 실험 설계의 정확도를 향상시키고, 데이터 분석 시간을 단축시키며, 연구 결과의 품질을 향상시키는 성과를 거두었습니다.",
            },
            en: {
                title: "Bio Technology",
                detail: "DIZEST has also been used for cell analysis research using lens-free technology. Even biotechnology researchers who are not familiar with code development can easily conduct machine learning-based research. In fact, the biotechnology research team adopted DIZEST and achieved results that improved the accuracy of experimental design, shortened data analysis time, and improved the quality of research results.",
            }
        }, {
            index: "03",
            src: "/assets/page/solutions/dizest/case-3.png",
            ko: {
                title: "조선소 자동화",
                detail: "조선업은 복잡한 공정과 다양한 전문 분야가 얽혀 있는 큰 규모의 산업 현장입니다. 따라서 현장 담당자와 연구 개발팀 간의 효율적인 소통이 중요합니다. DIZEST는 이러한 조선소 환경에서 특히 중요한 역할을 수행하였습니다. 조선소의 현장 담당자들은 전문적인 지식과 경험을 가지고 있지만, 종종 머신러닝이나 데이터 과학 분야에 대한 전문성은 부족할 수 있습니다. DIZEST는 이러한 경험적 지식과 머신러닝 기술을 연결하는 역할을 했습니다.",
            },
            en: {
                title: "Shipyard Automation",
                detail: "The shipbuilding industry is a large-scale industrial field with complex processes and various specialized fields intertwined. Therefore, efficient communication between field personnel and the research and development team is important. DIZEST played a particularly important role in this shipyard environment. Shipyard field personnel have specialized knowledge and experience, but often lack expertise in machine learning or data science. DIZEST played a role in connecting this empirical knowledge with machine learning technology.",
            }
        }, {
            index: "04",
            src: "/assets/page/solutions/dizest/case-4.png",
            ko: {
                title: "반도체 장비 모니터링",
                detail: "반도체 산업에서 사용되는 장비들은 다양한 전기 신호를 생성하면서 유기적으로 연결되는 구조를 가지고 있고 짧은 시간에 수많은 데이터를 생성합니다. 반도체 산업에서 사용하고 있는 장비 중 하나인 플라즈마 장비에서 생성되는 수많은 정보를 분석하기 위해 DIZEST를 활용하여 실시간 장비 상태 모니터링 도구를 개발하는데 성공하였습니다.",
            },
            en: {
                title: "Semiconductor Equipment Monitoring",
                detail: "Equipment used in the semiconductor industry has an organically connected structure that generates various electrical signals and generates a large amount of data in a short period of time. We succeeded in developing a real-time equipment status monitoring tool using DIZEST to analyze numerous information generated from plasma equipment, which is one of the equipment used in the semiconductor industry.",
            }
        }, {
            index: "05",
            src: "/assets/page/solutions/dizest/case-5.png",
            ko: {
                title: "농업 엣지 컴퓨팅",
                detail: "농업 분야는 ICT 기술을 도입하기에 매우 열악한 환경을 가지고 있습니다. 농장 내에서는 중량 기계와 장비가 활용되며, 이들로 인해 센서나 기기들이 손상될 수 있습니다. 또한, 많은 농장들은 도시 중심지에서 멀리 떨어진 지역에 위치해 있어 통신 기반 인프라가 약할 수 있습니다. 이로 인해 실시간 데이터 전송이나 원격 제어가 어려울 수 있습니다. DIZEST는 이러한 문제를 해결하기 위해 엣지 디바이스에서 활용 가능한 수준의 성능을 확보하여 열악한 환경에서의 적용도 고려하고 있습니다.",
            },
            en: {
                title: "Edge Computing at Agricultural",
                detail: "The agricultural sector has a very poor environment for introducing ICT technology. Heavy machinery and equipment are used on farms, which can damage sensors and devices. Additionally, many farms are located far from urban centers and may have weak communications infrastructure. This can make real-time data transmission or remote control difficult. To solve these problems, DIZEST secures a level of performance that can be used in edge devices and is also considering application in harsh environments.",
            }
        }, {
            index: "06",
            src: "/assets/page/solutions/dizest/case-6.png",
            ko: {
                title: "시스템 모니터링",
                detail: "DIZEST는 연구 목적이나 제조업 이외에도 다양한 적용을 시도하고 있습니다. 예를 들면, 자사 GPU 서버 상태를 모니터링하는 시스템에 활용하고 있습니다. DIZEST를 활용하면, GPU 서버의 상태를 실시간으로 효과적으로 모니터링하며, 다양한 분석 및 시각화 도구를 활용해 상황에 따른 적절한 대응을 할 수 있습니다. 이는 서버 운영의 효율성을 높이고, 장애 상황을 미리 예방하는 데 크게 기여합니다.",
            },
            en: {
                title: "System Monitoring",
                detail: "DIZEST is attempting various applications beyond research purposes or manufacturing. For example, we are using it in a system that monitors the status of our GPU servers. By using DIZEST, you can effectively monitor the status of your GPU server in real time and utilize various analysis and visualization tools to respond appropriately to the situation. This greatly contributes to increasing the efficiency of server operation and preventing failure situations in advance.",
            }
        }
    ];

    public info(item: any) {
        let lang: string = this.service.lang.get();
        if (item[lang]) return item[lang];
        return {};
    }

    public async select(item: any) {
        this.selected = item;
        await this.service.render();
    }

    public async github() {
        let url = "https://dizest.net";
        window.open(url);
    }
}

export default PortalWebSolutionDizestComponent;